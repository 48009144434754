// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-films-js": () => import("./../../../src/pages/films.js" /* webpackChunkName: "component---src-pages-films-js" */),
  "component---src-pages-hire-js": () => import("./../../../src/pages/hire.js" /* webpackChunkName: "component---src-pages-hire-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stills-js": () => import("./../../../src/pages/stills.js" /* webpackChunkName: "component---src-pages-stills-js" */)
}

